import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate, useSearchParams } from "react-router-dom";
import CustomDialog from "../Common/CustomDialog";
import Loader from "../Common/Loader";
import axios from "axios";
import { getAuthHeader, getJwtToken, isUserLoggedIn } from "../../Services/userService";
import { allRoutes } from "../../Routes/AllRoutes";
import http from "../../Services/httpService";
import { Box, Button, Typography } from "@mui/material";
import CustomButton from "../Common/CustomButton";

const AcceptCustomerSupportChatInvite: React.FC = () => {
	const [searchParams] = useSearchParams();
	const channelId = searchParams.get("channelId");
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const token = isUserLoggedIn();
		if (!token) {
			sessionStorage.setItem("channelId", channelId || "");
			navigate(allRoutes.LOGIN);
		}
	}, [navigate, channelId]);

	const acceptSupportChatInviteService = async (channelId: string) => {
		const config = {
			headers: {
				...getAuthHeader()
			},
		};

		const body = {
			channelId,
		};

		const response = await http.post(`/chat/accept-support-chat-invite`, body, {
			headers: {
				...getAuthHeader()
			}
		});
		return response.data;
	};

	const acceptInvite = async () => {
		if (!channelId) return;
		setLoading(true);
		try {
			await acceptSupportChatInviteService(channelId);
			toast.success("Chat invite accepted successfully!");
			navigate(allRoutes.CSR_CHAT); // Redirect to the support dashboard or appropriate page
		} catch (error) {
			toast.error("Failed to accept chat invite. Please try again.");
		} finally {
			setLoading(false);
		}
	};

	return (
		<CustomDialog open={true} maxWidth={"sm"}>
			<Loader open={loading} />
				<Box sx={{display: "flex", flexDirection: "column", alignItems: "center"}}>
				<Typography variant="h2">Accept Customer Support Request</Typography>
				<Box sx={{display: "flex", gap: "12px"}}>
				<CustomButton onClick={acceptInvite} sx={{ marginTop: "40px"}}>
					Accept 
				</CustomButton>
				<CustomButton onClick={() => navigate(allRoutes.DASHBOARD)} sx={{ marginTop: "40px"}}>
					Reject
				</CustomButton>
				</Box>
				</Box>

		</CustomDialog>
	);
};

export default AcceptCustomerSupportChatInvite;
