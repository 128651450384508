export const surveyStatuses = {
	SCHEDULED: "scheduled",
	PAYMENT_DEPOSITED: "paymentdeposited",
	PENDING: "pending",
	COMPLETED: "completed",
};

export const steps = {
	SITE_SURVEY: "Site Survey",
	CAD_DESIGN: "CAD Design",
	PERMIT_APPLICATION: "Permit Application",
	UTILITY_INTERCONNECTION: "Utility Interconnection",
	INSTALLATION: "Installation",
	TOWN_INSPECTION: "Town Inspection",
	METER_REPLACEMENT: "Meter Replacement",
	ACTIVATION: "Activation",
	CONTRACT_SIGNED: "Contract",
	LEAD: "Lead",
	ACCEPTED_PROPOSAL: "Accepted Proposal",
};

export const dashboards = {
	FRONTEND_CUSTOMER_APP: "fe-customer-app",
	INSTALLER_DASHBOARD: "installer-dashboard-app",
	ADMIN_DASHBOARD: "admin-dashboard-app",
};

export const leadStatuses = {
	APPOINTMENT_SCHEDULED: "Appointment Scheduled",
	DEMO_NOT_SOLD: "Demo - Not Sold",
	DQ_CREDIT: "DQ - Credit",
	DQ_RENTER: "DQ - Renter",
	DQ_ROOF: "DQ - Roof",
	DQ_SHADING: "DQ - Shading",
	DQ_METER: "DQ - Meter",
	DQ_ELECTRICAL: "DQ - Electrical",
	DQ_HAS_SOLAR: "DQ - Has Solar",
	INTERESTED_HIGH: "Interested - High",
	INTERESTED_MEDIUM: "Interested - Medium",
	INTERESTED_LOW: "Interested - Low",
	RESCHEDULED: "Rescheduled",
	NO_SHOW: "No Show",
	NOT_INTERESTED: "Not Interested",
};
