import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import PageLayout from "../PageLayout/PageLayout";
import { allRoutes } from "../../Routes/AllRoutes";
import TableBlock from "../Common/Table/TableBlock";
import { useNavigate } from "react-router-dom";
import CustomTableOptions from "../Common/CustomTableOptions";
import axios from "axios";
import { getAuthHeader } from "../../Services/userService";

const CustomerServiceRepresentative = () => {
	const navigate = useNavigate();

	const [loading, setLoading] = useState<boolean>(false);
	const [data, setData] = useState<Array<any>>([]);

	const tableHeaders = [
		{ text: "ID", key: "sequentialId", showEllipses: true, maxWidth: 75 },
		{ text: "Name", key: "name", showEllipses: true, maxWidth: 100, sortable: true },
		{ text: "Email address", key: "email", showEllipses: true, maxWidth: 130, sortable: true },
		{ text: "Email Status", key: "isEmailVerified" },
	];

	useEffect(() => {
		getAllUsers();
	}, []);

	const getAllUsers = async () => {
		setLoading(true);
		try {
			const response = await axios.get(`${process.env.REACT_APP_API_URL}/csr`, {
				headers: {
					...getAuthHeader()
				}
			});

			setData(response.data.data);
		} catch (error: any) {
			toast.error(error.message || "An error occurred");
		}
		setLoading(false);
	};

	return (
		<PageLayout loading={loading} hideBackButton>
			<TableBlock
				heading="Customer Service Representative"
				subHeading="These are all the Customer Service Representative:"
				tableData={data}
				addButtonText="Add Customer Service Representative"
				addButtonPath={allRoutes.ADD_CSR}
				detailsPagePath={allRoutes.VIEW_CSR}
				tableHeaders={tableHeaders}
				emptyStateMessage="There are no customer service representative. Please add a customer service representative"
				rowsPerPage={10}
			/>
		</PageLayout>
	);
};

export default CustomerServiceRepresentative;
