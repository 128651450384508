import { Avatar, Box, IconButton } from "@mui/material";
import { useRef } from "react";
import { toast } from "react-toastify";
import { useSelector } from "../../Redux/reduxHooks";
import { selectColors } from "../../Redux/Slices/generalSlice";
import { CancelOutlined } from "@mui/icons-material";

interface ImageUploaderProps {
    onUpdate: any;
    imageFile?: any;
    size?: number;
    isLogo?: boolean;
    acceptIcon?: boolean;
}

const ImageUploader = ({ onUpdate, imageFile, size = 129, isLogo = false, acceptIcon = false }: ImageUploaderProps) => {
    const colors = useSelector(selectColors);
    const inputRef = useRef<any>(null);

    const handleImageUploader = (event: any) => {
        const selectedImage = event.target.files[0];

        if (!selectedImage) return;
        if (selectedImage?.size > 1572864) {
            // Max image size set to 1.5MB, 1,048,576 * 1.5 = 1,572,864 Bytes
            // 1MB = 1,048,576 Bytes
            toast.error("The selected image exceeds the maximum allowed size. Please choose a smaller image file.");
        } else {
            onUpdate(selectedImage);
        }
        event.target.value = null; // resetting the value of input
    };

    const handleRemoveImage = () => {
        onUpdate("");
    };
    console.log({ imageFile });

    return (
        <Box
            sx={{
                position: "relative",
                width: isLogo ? size * 1.5 : size,
                height: isLogo ? size / 2 : size,
            }}
        >
            <input
                ref={inputRef}
                type="file"
                name="myImage"
                accept={acceptIcon ? "image/x-icon" : "image/png, image/jpeg, image/jpg"}
                onChange={handleImageUploader}
                style={{ display: "none" }}
            />

            <Avatar
                sx={{
                    cursor: "pointer",
                    width: isLogo ? size * 1.5 : size,
                    height: isLogo ? size / 2 : size,
                    border: `1px solid ${colors.border}`,
                    borderRadius: isLogo ? "10px" : "50%",
                    padding: isLogo ? 2 : 0,
                }}
                src={
                    typeof imageFile === "string" ? imageFile : imageFile instanceof Blob ? URL.createObjectURL(imageFile) : ""
                }
                onClick={() => inputRef?.current?.click()}
                imgProps={{ style: { objectFit: "contain" } }}
            />

            {!!imageFile && (
                <IconButton
                    sx={{ position: "absolute", top: 2, right: 2, p: 0, bgcolor: "#ffffff" }}
                    onClick={handleRemoveImage}
                >
                    <CancelOutlined />
                </IconButton>
            )}
        </Box>
    );
};

export default ImageUploader;
