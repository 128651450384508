import React, { useEffect, useState } from 'react';
import { StreamChat, Channel as StreamChannel, DefaultGenerics } from 'stream-chat';
import 'stream-chat-react/dist/css/index.css';
import {
  Chat,
  Channel,
  ChannelHeader,
  Window,
  MessageList,
  ChannelList,
  ChannelPreviewMessenger,
  ChannelPreviewUIComponentProps,
  MessageInput,
} from 'stream-chat-react';
import { getAuthHeader } from '../../Services/userService';
import { selectUser } from '../../Redux/Slices/userSlice';
import { useSelector } from 'react-redux';
import Loader from '../Common/Loader';
import PageLayout from '../PageLayout/PageLayout';
import { Box, IconButton, Theme, Tooltip, useMediaQuery } from '@mui/material';
import { navbarHeight } from '../../Utils/spacings';
import ReassignChatModal from './ReassignChatModal';
import CustomButton from '../Common/CustomButton';
import { redirectToUserDashboard } from '../Customers/CustomerDetails';
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Assignment, Close, RemoveRedEye, VideoCallOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { allRoutes } from '../../Routes/AllRoutes';

const streamApiKey = `${process.env.REACT_APP_GET_STREAM_API_KEY}`;

const fetchRepToken = async (): Promise<string> => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/chat/get-rep-token`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...getAuthHeader(),
    },
  });

  if (!response.ok) {
    throw new Error('Failed to fetch representative token');
  }

  const data = await response.json();
  return data.data;
};


interface CustomChannelPreviewProps extends ChannelPreviewUIComponentProps {
  activeChannelId: string | undefined;
  setActiveChannel: React.Dispatch<React.SetStateAction<StreamChannel<DefaultGenerics> | undefined>>;
}

const CustomChannelPreview: React.FC<CustomChannelPreviewProps> = ({ channel, setActiveChannel, activeChannelId, ...props }) => {
  const handleClick = () => {
    setActiveChannel(channel);
  };

  return (
    <div
      onClick={handleClick}
      style={{
        backgroundColor: activeChannelId === channel.id ? '#e0f7fa' : 'transparent',
        cursor: 'pointer',
      }}
    >
      <ChannelPreviewMessenger {...props} channel={channel} />
    </div>
  );
};


const RepresentativeChat: React.FC = () => {
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const [channel, setChannel] = useState<StreamChannel<DefaultGenerics> | undefined>(undefined);
  const [client, setClient] = useState<StreamChat<DefaultGenerics> | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isChannelFrozen, setIsChannelFrozen] = useState<boolean>(false);
  const [isReassignModalOpen, setIsReassignModalOpen] = useState(false);
  const user = useSelector(selectUser);

  useEffect(() => {
    const initializeChat = async () => {
      if (client) return;

      try {
        const repToken = await fetchRepToken();
        const chatClient = StreamChat.getInstance(streamApiKey);

        if (user?.id) {
          await chatClient.connectUser(
            {
              id: String(user.id),
              name: user?.name || '',
            },
            repToken
          );

          setClient(chatClient);
        } else {
          throw new Error('User ID is undefined');
        }
      } catch (err: unknown) {
        if (err instanceof Error) {
          console.error('Error initializing chat:', err.message);
          setError(err.message);
        } else {
          console.error('Unknown error initializing chat:', err);
          setError('An unknown error occurred.');
        }
      }
    };

    initializeChat();

    return () => {
      if (client) {
        client.disconnectUser();
        setClient(null); // Ensure client is set to null after disconnection
      }
    };
  }, [user, client]);


  useEffect(() => {
    if (client) {
      const selectFirstChannel = async () => {
        const channels = await client.queryChannels({ type: 'messaging', members: { $in: [String(user?.id)] } });

        if (channels.length > 0) {
          setChannel(channels[0]);
        }
      };
      selectFirstChannel();
    }
  }, [client, user]);

  useEffect(() => {

    setIsChannelFrozen(!!channel?.data?.frozen || false)

  }, [channel, channel?.data?.frozen])


  const startVideoCall = async (channelId: string) => {
    try {
      const repToken = await fetchRepToken();
      const callLink = `${process.env.REACT_APP_CUSTOMER_URL}/join?call_id=${channelId}&call_type=default`;

      if (channel) {
        await channel.sendMessage({
          text: `Please join the video call using the following link: ${callLink}`,
        });

        navigate(allRoutes.CSR_VIDEO_CHAT, {
          state: {
            apiKey: streamApiKey,
            token: repToken,
            userId: user?.id || '',
            chatId: channelId,
            repName: user?.name,
            isAdmin: true
          },
        });
      }
    } catch (error) {
      console.error('Error starting video call:', error);
    }
  };

  const endChat = async (channel: StreamChannel<DefaultGenerics>, isAdminRole: boolean) => {
    try {
      await channel.sendMessage({
        text: isAdminRole ? `Chat ended. Thank you!` : "",
      });
      await channel.updatePartial(
        { set: { frozen: true } }
      )
      setIsChannelFrozen(true)
    } catch (error) {
      console.error('Error ending chat:', error);
    }
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!client) {
    return <Loader open />;
  }

  // Extract all member IDs
  const memberIds = channel?.state?.members ? Object.keys(channel.state.members) : [];
  const cb: any = channel?.data?.created_by
  const customerId = cb?.id || ''
  const isAnonUser = customerId.length < 24
  return (
    <PageLayout hideBackButton removePadding>
      <Box
        sx={{
          '& .str-chat': { height: `calc(100vh - ${navbarHeight}px - 8px) !important` },
          '& .str-chat__channel-preview-messenger--active': { background: 'transparent !important' },
          "& .str-chat__input-flat-fileupload": { display: "none" }, "& .str-chat__message-simple-name": { display: "none" },
          "& .str-chat__message-options": { display: "none" }
        }}
      >
        <Chat client={client} theme={'messaging light'}>
          <ChannelList
            filters={{ type: 'messaging', members: { $in: [String(user?.id)] } }}
            sort={{ last_message_at: -1 }}
            Preview={(props) => (
              <CustomChannelPreview {...props} activeChannelId={channel?.id} setActiveChannel={setChannel} />
            )}
          />
          {channel && (
            <Channel channel={channel}>
              <Window>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    position: 'relative',
                    '& .str-chat__header-livestream': { width: '100%' },
                    "& .str-chat__channel-header-info": { display: "none" }
                  }}
                >
                  <ChannelHeader />
                  <Box
                    sx={{
                      display: "flex", alignItems: "center", justifyContent: "flex-end", position: "absolute",
                      right: "20px", zIndex: "2", gap: "8px", "& button": {
                        padding: "4px 8px", fontSize: "12px",
                        ...(isSmallScreen ? { width: 30, height: 30, minWidth: 0 } : {})
                      }
                    }}
                  >

                    {isSmallScreen ? <>

                      <IconButton disabled={isChannelFrozen} onClick={() => {
                        if (channel.id) {
                          startVideoCall(channel.id);
                        }
                      }}><VideoCallOutlined /></IconButton>

                      <Tooltip arrow title={isAnonUser ? "Cannot view customers who have not signed up." : "View customer"}>
                        <Box>

                          <IconButton disabled={isAnonUser} onClick={() => {
                            navigate(allRoutes.VIEW_CUSTOMER.replace(":id", String(customerId || '')));
                          }}>
                            <RemoveRedEye />
                          </IconButton>
                        </Box>
                      </Tooltip>
                      <Tooltip arrow title={isAnonUser ? "Cannot view customers proposals who have not signed up." : "View customer proposal"}>
                        <Box>

                          <IconButton disabled={isAnonUser} onClick={() => {
                            redirectToUserDashboard(customerId)
                          }}><OpenInNewIcon /> </IconButton>
                        </Box>
                      </Tooltip>
                      <Tooltip arrow title={isChannelFrozen ? "Cannot reassign to a close chat" : "Reassign chat"}>
                        <Box>

                          <IconButton disabled={isChannelFrozen} onClick={() => {
                            setIsReassignModalOpen(true)
                          }}><Assignment /> </IconButton>
                        </Box>
                      </Tooltip>
                      <IconButton color='error' disabled={isChannelFrozen} onClick={() => {
                        if (channel) {
                          endChat(channel, true);
                        }
                      }}> <Close /></IconButton>

                    </> : <>
                      <CustomButton variant='outlined' disabled={isChannelFrozen} startIcon={<VideoCallOutlined />} onClick={() => {
                        if (channel.id) {
                          startVideoCall(channel.id);
                        }
                      }}>{isSmallScreen ? "" : `Start Video Call`}</CustomButton>
                      <Tooltip arrow title={isAnonUser ? "Cannot view customers who have not signed up." : ""}>
                        <Box>

                          <CustomButton disabled={isAnonUser} variant='outlined' startIcon={<RemoveRedEye />} onClick={() => {
                            navigate(allRoutes.VIEW_CUSTOMER.replace(":id", String(customerId || '')));
                          }}>
                            {isSmallScreen ? "" : `View Customer`}
                          </CustomButton>
                        </Box>

                      </Tooltip>

                      <Tooltip arrow title={isAnonUser ? "Cannot view customers proposals who have not signed up." : ""}>
                        <Box>

                          <CustomButton disabled={isAnonUser} variant='outlined' startIcon={<OpenInNewIcon />} onClick={() => {
                            redirectToUserDashboard(customerId)
                          }}>{isSmallScreen ? "" : `View Customer Proposal`}</CustomButton>
                        </Box>
                      </Tooltip>
                      <CustomButton disabled={isChannelFrozen} variant="contained" color="primary" startIcon={<Assignment />} onClick={() => setIsReassignModalOpen(true)}>
                        Reassign Chat
                      </CustomButton>
                      <CustomButton variant='outlined' color='error' disabled={isChannelFrozen} startIcon={<Close />} onClick={() => {
                        if (channel) {
                          endChat(channel, true);
                        }
                      }}>{isSmallScreen ? "" : `End Chat`}</CustomButton>
                    </>}
                  </Box>
                </Box>
                <MessageList />
                <MessageInput focus disabled={isChannelFrozen} />
              </Window>
            </Channel>
          )}
        </Chat>
      </Box>
      <ReassignChatModal open={isReassignModalOpen} onClose={() => setIsReassignModalOpen(false)} channelId={channel?.id} oldRepIds={memberIds} />
    </PageLayout>
  );
};

export default RepresentativeChat;
