import http from "./httpService";
import { getAuthHeader } from "./userService";

const apiEndpoint = "/chat-config-msg";

// =====|  Dashboard Service  |=====

const DefaultMsgService = {
	getDefaultMsg: () => http.get(`${apiEndpoint}/`),
	updateDefaultMsg: (data: any) => http.put(`${apiEndpoint}/update`, data, { headers: { ...getAuthHeader() } }),
};

// =====|  APIs  |=====

export const getDefaultMsg = () => {
	return DefaultMsgService.getDefaultMsg();
};

export const updateDefaultMsg = (data: any) => {
	return DefaultMsgService.updateDefaultMsg(data);
};
