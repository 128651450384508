import React, { useEffect, useState } from 'react';
import PageLayout from '../PageLayout/PageLayout';
import { Divider, Typography, CircularProgress, Box, List, ListItem, ListItemText, Tooltip, IconButton, Menu, MenuItem } from '@mui/material';
import { MoreVert, Add, SearchOutlined } from '@mui/icons-material';
import axios from 'axios';
import CustomPagination from '../Common/CustomPagination'; // Assuming you have a CustomPagination component
import CustomTextField from '../Common/CustomTextField'; // Assuming you have a CustomTextField component
import CustomButton from '../Common/CustomButton';
import CustomDialog from '../Common/CustomDialog'; // Import the CustomDialog component
import { useNavigate } from "react-router-dom";
import { allRoutes } from '../../Routes/AllRoutes';
import { getAuthHeader } from '../../Services/userService';
import { toast } from 'react-toastify'; // Import toast

const FaqPage = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(true);
    const [faqs, setFaqs] = useState<any[]>([]);
    const [filteredFaqs, setFilteredFaqs] = useState<any[]>([]);
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedFaq, setSelectedFaq] = useState<any>(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
    const [viewAnswerDialogOpen, setViewAnswerDialogOpen] = useState<boolean>(false); // State for viewing answer modal
    const [viewAnswerFaq, setViewAnswerFaq] = useState<any>(null); // State for FAQ to view full answer

    // Pagination states
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [faqsPerPage] = useState<number>(5);

    // Search state
    const [searchQuery, setSearchQuery] = useState<string>('');

    useEffect(() => {
        const fetchFaqs = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/faq`);
                console.log('Full API response:', response); // Debugging step
                console.log('API response data:', response.data); // Debugging step

                const data = response.data;
                if (data && Array.isArray(data.data)) {
                    setFaqs(data.data);
                    setFilteredFaqs(data.data); // Initialize filteredFaqs with fetched data
                } else {
                    console.error('API response does not contain a valid FAQs array:', data);
                    setFaqs([]); // Fallback to empty array
                    setFilteredFaqs([]); // Fallback to empty array
                }
            } catch (error) {
                console.error('Error fetching FAQs:', error);
                setFaqs([]); // Fallback to empty array in case of error
                setFilteredFaqs([]); // Fallback to empty array in case of error
            } finally {
                setLoading(false);
            }
        };

        fetchFaqs();
    }, []);

    // Updates filtered FAQs based on the search query
    useEffect(() => {
        if (searchQuery) {
            const lowercasedQuery = searchQuery.toLowerCase();
            const searchedFaqs = faqs.filter(
                (faq) =>
                    faq.question.toLowerCase().includes(lowercasedQuery) ||
                    faq.answer.toLowerCase().includes(lowercasedQuery)
            );
            setFilteredFaqs(searchedFaqs);
            setCurrentPage(1);
        } else {
            setFilteredFaqs(faqs);
        }
    }, [searchQuery, faqs]);

    // Pagination logic
    const indexOfLastFaq = currentPage * faqsPerPage;
    const indexOfFirstFaq = indexOfLastFaq - faqsPerPage;
    const currentFaqs = filteredFaqs.slice(indexOfFirstFaq, indexOfLastFaq);
    const totalPages = Math.ceil(filteredFaqs.length / faqsPerPage);

    // Menu handlers
    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, faq: any) => {
        setMenuAnchorEl(event.currentTarget);
        setSelectedFaq(faq);
        console.log("Selected FAQ set: ", faq); // Debugging step
    };

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };

    // Delete dialog handlers
    const handleDeleteDialogOpen = () => {
        setDeleteDialogOpen(true);
        handleMenuClose();
    };

    const handleDeleteDialogClose = () => {
        setDeleteDialogOpen(false);
        setSelectedFaq(null);
    };

    const handleDeleteFaq = async () => {
        if (!selectedFaq) {
            console.log("No FAQ selected for deletion");
            return;
        }
        console.log("Deleting FAQ: ", selectedFaq); // Debugging step
        try {
            const token = getAuthHeader(); // Replace with your actual token
            await axios.delete(`${process.env.REACT_APP_API_URL}/faq/${selectedFaq._id}`, {
                headers: token
            });
            setFaqs(faqs.filter(faq => faq._id !== selectedFaq._id));
            setFilteredFaqs(filteredFaqs.filter(faq => faq._id !== selectedFaq._id));
            handleDeleteDialogClose();
            console.log("FAQ deleted successfully");
            toast.success("FAQ deleted successfully!"); // Show success toast
            navigate(allRoutes.FAQS); // Navigate back to the FAQ page
        } catch (error) {
            console.error('Error deleting FAQ:', error);
            toast.error('Error deleting FAQ'); // Show error toast
        }
    };

    const truncateAnswer = (answer: string) => {
        const maxLength = 190; // Set the max length for the truncated answer
        if (answer.length > maxLength) {
            return `${answer.substring(0, maxLength)}...`;
        }
        return answer;
    };

    const handleViewAnswerDialogOpen = (faq: any) => {
        setViewAnswerFaq(faq);
        setViewAnswerDialogOpen(true);
    };

    const handleViewAnswerDialogClose = () => {
        setViewAnswerFaq(null);
        setViewAnswerDialogOpen(false);
    };

    return (
        <PageLayout loading={loading} hideBackButton>
            <Box
                display="flex"
                alignItems={{ xs: 'stretch', md: 'center' }}
                justifyContent="space-between"
                flexDirection={{ xs: 'column', md: 'row' }}
                gap={12}
                mb={32}
            >
                <Typography variant="h4" mb={8}>
                    FAQs
                </Typography>
                <Box
                    display="flex"
                    alignItems={{ xs: 'stretch', md: 'center' }}
                    justifyContent="flex-end"
                    gap={12}
                    flexDirection={{ xs: 'column', md: 'row' }}
                >
                    <CustomTextField
                        placeholder="Search FAQs"
                        variant="outlined"
                        value={searchQuery}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchQuery(e.target.value)}
                        startIcon={<SearchOutlined sx={{ opacity: 0.7 }} />}
                    />
                    <Tooltip title={'Add New Faq'} arrow>
                        <span>
                            <CustomButton
                                sx={{ height: 40.13, minWidth: "max-content" }}
                                startIcon={<Add />}
                                onClick={() => navigate(allRoutes.ADD_FAQ || "", { state: allRoutes.ADD_FAQ })}
                            >
                                {'Add New Faq'}
                            </CustomButton>
                        </span>
                    </Tooltip>
                </Box>
            </Box>
            <Divider sx={{ mb: 4 }} />
            {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <List>
                        {currentFaqs.map((faq) => (
                            <ListItem key={faq._id} sx={{ mb: 3 }}>
                                <ListItemText
                                    primary={<Typography variant="h6">{faq.question}</Typography>}
                                    secondary={
                                        <>
                                            <Typography variant="body1">
                                                {truncateAnswer(faq.answer)}
                                            </Typography>
                                            {faq.answer.length > 190 && (
                                                <Typography
                                                    variant="body2"
                                                    color="primary"
                                                    onClick={() => handleViewAnswerDialogOpen(faq)}
                                                    sx={{ cursor: 'pointer' }}
                                                >
                                                    Read more
                                                </Typography>
                                            )}
                                        </>
                                    }
                                />
                                <IconButton
                                    aria-label="more"
                                    aria-controls="faq-menu"
                                    aria-haspopup="true"
                                    onClick={(event) => handleMenuOpen(event, faq)}
                                >
                                    <MoreVert />
                                </IconButton>
                            </ListItem>
                        ))}
                    </List>
                    <Box display={'flex'} justifyContent={'end'} mt={4}>
                        <CustomPagination
                            totalPages={totalPages}
                            currentPage={currentPage}
                            onPageChange={(page: number) => setCurrentPage(page)}
                        />
                    </Box>
                </>
            )}

            <Menu
                id="faq-menu"
                anchorEl={menuAnchorEl}
                keepMounted
                open={Boolean(menuAnchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={() => navigate(allRoutes.EDIT_FAQ.replace(":id", (selectedFaq?._id || "")?.toString()))}>Edit</MenuItem>
                <MenuItem onClick={handleDeleteDialogOpen}>Delete</MenuItem>
            </Menu>

            <CustomDialog
                open={deleteDialogOpen}
                onClose={handleDeleteDialogClose}
                maxWidth={440}
                iconColor='red'
            >
                <Typography variant="h6" mb={2}>Delete FAQ</Typography>
                <Typography mb={4}>Are you sure you want to delete this FAQ?</Typography>
                <Box display="flex" justifyContent="flex-end" gap={2}>
                    <CustomButton onClick={handleDeleteDialogClose} color="secondary">
                        Cancel
                    </CustomButton>
                    <CustomButton
                        onClick={() => {
                            console.log("Delete button clicked"); // Debugging step
                            handleDeleteFaq();
                        }}
                        color="primary"
                    >
                        Delete
                    </CustomButton>
                </Box>
            </CustomDialog>

            <CustomDialog
                open={viewAnswerDialogOpen}
                onClose={handleViewAnswerDialogClose}
                maxWidth={600}
                iconColor='black'
            >
                <Typography variant="h6" mb={2}>{viewAnswerFaq?.question}</Typography>
                <Typography variant="body1">{viewAnswerFaq?.answer}</Typography>
                <Box display="flex" justifyContent="flex-end" gap={2} mt={4}>
                    <CustomButton onClick={handleViewAnswerDialogClose} color="primary">
                        Close
                    </CustomButton>
                </Box>
            </CustomDialog>
        </PageLayout>
    );
};

export default FaqPage;
