import React, { FormEvent, useEffect, useState } from "react";
import CustomButton from "../Common/CustomButton";
import CustomTextField, { Asterisk } from "../Common/CustomTextField";
import { Box, CircularProgress, Divider, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import PageLayout from "../PageLayout/PageLayout";
import axios from "axios";
import { allRoutes } from "../../Routes/AllRoutes";
import { TagsInput } from "../Common/CustomTagComponent";
import { getAuthHeader } from "../../Services/userService";

interface Faq {
  question: string;
  answer: string;
  keywords: string[];
}

interface FaqErrors {
  question?: string;
  answer?: string;
  keywords?: string;
}

const defaultData = {
  question: "",
  answer: "",
  keywords: [],
};

const EditFaq = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const [data, setData] = useState<Faq>(defaultData);
  const [keywords, setKeywords] = useState<string[]>([]);

  const [errors, setErrors] = useState<FaqErrors>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [isDataFetched, setIsDataFetched] = useState<boolean>(false);

  useEffect(() => {
    const fetchFaq = async () => {
      setLoading(true);
      try {
        const token = getAuthHeader(); // Replace with your actual token
        const response = await axios.get(`http://localhost:8000/faq/${id}`, {
          headers: token,
        });

        const faqData = response.data.data;
        setData({
          question: faqData.question,
          answer: faqData.answer,
          keywords: faqData.keywords,
        });
        setKeywords(faqData.keywords);
        setIsDataFetched(true);
      } catch (error: any) {
        toast.error(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchFaq();
  }, [id]);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setData((state) => ({ ...state, [name]: value }));
    setErrors((state) => ({ ...state, [name]: "" }));
  };

  const validateData = () => {
    const updatedErrors: FaqErrors = {};

    updatedErrors.question = data.question ? "" : "Question cannot be empty";
    updatedErrors.answer = data.answer ? "" : "Answer cannot be empty";
    updatedErrors.keywords = keywords.length > 0 ? "" : "Keywords cannot be empty";

    setErrors(updatedErrors);
    return !Object.values(updatedErrors).find(Boolean);
  };

  const handleUpdate = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validateData()) return;

    setLoading(true);
    try {
      const token = getAuthHeader(); // Replace with your actual token
      await axios.put(
        `http://localhost:8000/faq/${id}`,
        { ...data, keywords },
        {
          headers: token,
        }
      );

      toast.success("FAQ updated successfully!");
      navigate(allRoutes.FAQS);
    } catch (error: any) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  const handleCancel = () => {
    navigate(allRoutes.FAQS);
  };

  return (
    <PageLayout loading={loading}>
      <Typography variant="h5">Edit FAQ</Typography>
      <Typography fontSize={15} mt={10}>
        Edit details of the FAQ
      </Typography>
      <Divider sx={{ mt: 14, mb: 24 }} />

      {isDataFetched ? (
        <form onSubmit={handleUpdate}>
          <Box
            display="grid"
            gridTemplateColumns={{ xs: "1fr", md: "340px 1fr" }}
            gap={{ xs: 10, md: 32 }}
            alignItems="start"
          >
            <Typography variant="h6" fontSize={18} mt={{ xs: 12, md: 0 }}>
              Question
              <Asterisk />
            </Typography>
            <CustomTextField
              onChange={handleOnChange}
              value={data.question}
              name="question"
              placeholder="Enter the question"
              error={errors.question}
            />

            <Typography variant="h6" fontSize={18} mt={{ xs: 12, md: 0 }}>
              Answer
              <Asterisk />
            </Typography>
            <CustomTextField
              onChange={handleOnChange}
              value={data.answer}
              error={errors.answer}
              name="answer"
              placeholder="Enter the answer"
              multiline
            />

            <Typography variant="h6" fontSize={18} mt={{ xs: 12, md: 0 }}>
              Keywords
              <Asterisk />
            </Typography>

            <TagsInput
              errors={errors}
              setErrors={setErrors}
              value={keywords}
              onChange={setKeywords}
              name="keywords"
              placeHolder="Enter keywords"
              type="text"
            />

            <Box />
            <Box display="flex" alignItems="center" justifyContent="flex-end" gap={20}>
              <CustomButton variant="outlined" color="secondary" onClick={handleCancel}>
                Cancel
              </CustomButton>
              <CustomButton type="submit">Update FAQ</CustomButton>
            </Box>
          </Box>
        </form>
      ) : (
      <></>
      )}
    </PageLayout>
  );
};

export default EditFaq;
