import { FormEvent, useEffect, useState } from "react";
import { UserState, selectUser } from "../../Redux/Slices/userSlice";
import { useDispatch, useSelector } from "../../Redux/reduxHooks";
import CustomButton from "../Common/CustomButton";
import CustomTextField, { Asterisk } from "../Common/CustomTextField";
import { Box, Divider, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { allRoutes } from "../../Routes/AllRoutes";
import { updateProfile } from "../../Services/profileService";
import ImageUploader from "../Common/ImageUploader";
import * as EmailValidator from "email-validator";
import GoogleMapsTextField, { PlaceType } from "../Common/GoogleMapsTextField";
import OtpVerifyDialog from "./OtpVerifyDialog";
import PageLayout from "../PageLayout/PageLayout";
import MuiPhoneNumber from "material-ui-phone-number";
import { validatePassword } from "../../Utils/utils";

interface AccountSettingsData extends UserState {
	changePassword?: string;
	addressObject?: PlaceType | null;
}

const defaultData = {
	name: "",
	email: "",
	addressObject: null,
	bill: "",
	phone: "",
	password: "",
	changePassword: "",
};

const AccountSettings = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const user = useSelector(selectUser);

	const [data, setData] = useState<AccountSettingsData>(defaultData);
	const [errors, setErrors] = useState<AccountSettingsData>(defaultData);
	const [loading, setLoading] = useState<boolean>(false);
	const [otpDialog, setOtpDialog] = useState<boolean>(false);
	const [profilePicture, setProfilePicture] = useState<any>("");
	const [updatingEmail, setUpdatingEmail] = useState<any>("");

	useEffect(() => {
		if (user) {
			const userData = {
				name: user.name,
				email: user.email,
				addressObject: user.addressObject
					? user.addressObject
					: user.address
					? {
							description: user.address,
							structured_formatting: {
								main_text: user.address,
								secondary_text: user.address,
							},
					  }
					: null,
				bill: user.bill,
				phone: user.phone,
			};
			setData(userData);
			setProfilePicture(user.picture);
		}
	}, [user]);

	const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setData((state) => ({ ...state, [name]: value }));
		setErrors((state) => ({ ...state, [name]: name === "password" ? validatePassword(value) : "" }));
	};

	const handleAddress = (value: PlaceType | null) => {
		setData((state) => ({ ...state, addressObject: value }));
		setErrors((state) => ({ ...state, address: "" }));
	};

	const handleSelectImage = (image: any) => {
		setProfilePicture(image);
	};

	const validateData = () => {
		const updatedErrors = { ...errors };

		updatedErrors.name = data.name ? "" : "Name cannot be empty";
		updatedErrors.email = data.email
			? !EmailValidator.validate(data.email)
				? "Enter a valid email"
				: ""
			: "Email cannot be empty";

		if (data.password || data.changePassword) {
			updatedErrors.password = validatePassword(data.password);
			updatedErrors.changePassword = data.changePassword
				? data.changePassword !== data.password
					? "Password does not match"
					: ""
				: "Confirm password cannot be empty";
		}

		setErrors(updatedErrors);
		return !Object.values(updatedErrors).find(Boolean);
	};

	const handleUpdateProfile = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (!validateData()) return;

		setLoading(true);
		try {
			let newEmail;
			if (user.email?.trim() !== data.email?.trim()) {
				newEmail = data.email;
				setUpdatingEmail(newEmail);
			}

			const formData = new FormData();
			formData.append("ImageUrl", profilePicture ?? "");
			formData.append("name", data.name ?? "");
			formData.append("email", data.email ?? "");
			formData.append("address", data.addressObject?.description ?? "");
			formData.append("electricity_usage", data.bill?.toString() ?? "");
			formData.append("phone_no", data.phone ?? "");

			formData.append("current_password", data.password ?? "");
			formData.append("change_password", data.changePassword ?? "");

			await dispatch(updateProfile(formData));

			if (newEmail) {
				// setting the old email in input field, if user decides to close the verify otp dialog the input will display the active previous email of user
				setData((state) => ({ ...state, email: user.email }));
				openOtpDialog();
			} else {
				toast.success("Profie updated successfully!");
			}
		} catch (error: any) {
			let specificError = error;
			if (specificError.includes("This email already exists.")) {
				specificError = "A user with this email already exists.";
				setErrors({ ...errors, email: specificError });
			} else {
				toast.error(specificError);
			}
		}
		setLoading(false);
	};

	const handleCancel = () => {
		navigate(allRoutes.DASHBOARD);
	};

	const openOtpDialog = () => setOtpDialog(true);
	const closeOtpDialog = () => setOtpDialog(false);

	return (
		<PageLayout loading={loading} hideBackButton>
			<Typography variant="h5">Account Settings</Typography>
			<Typography fontSize={15} mt={10}>
				Update your photo and personal details
			</Typography>
			<Divider sx={{ mt: 14, mb: 24 }} />

			<form onSubmit={handleUpdateProfile}>
				<Box
					display="grid"
					gridTemplateColumns={{ xs: "1fr", md: "340px 1fr" }}
					gap={{ xs: 10, md: 32 }}
					alignItems="center"
				>
					<Box alignSelf="flex-start">
						<Typography variant="h5">Your Photo</Typography>
						<Typography fontSize={15} mt={10}>
							This will be displayed on your profile
						</Typography>
					</Box>
					<Box>
						<ImageUploader onUpdate={handleSelectImage} imageFile={profilePicture} />
					</Box>

					<Typography variant="h6" fontSize={18} mt={{ xs: 12, md: 0 }}>
						Name
						<Asterisk />
					</Typography>
					<CustomTextField onChange={handleOnChange} value={data.name} name="name" placeholder="Name" />

					<Typography variant="h6" fontSize={18} mt={{ xs: 12, md: 0 }}>
						Email address
						<Asterisk />
					</Typography>
					<CustomTextField
						onChange={handleOnChange}
						value={data.email}
						error={errors.email}
						name="email"
						type="email"
						placeholder="@example"
					/>

					<Typography variant="h6" fontSize={18} mt={{ xs: 12, md: 0 }}>
						Address
					</Typography>
					<GoogleMapsTextField
						placeholder="Address"
						value={data.addressObject}
						onChange={handleAddress}
						error={errors.address}
					/>

					{/* <Typography variant="h6" fontSize={18} mt={{ xs: 12, md: 0 }}>
						Electricity Bill
					</Typography>
					<CustomTextField
						onChange={handleOnChange}
						value={data.bill}
						error={errors.bill?.toString()}
						name="bill"
						type="number"
						placeholder="2500"
						endIcon={<>/Mo</>}
					/> */}

					<Typography variant="h6" fontSize={18} mt={{ xs: 12, md: 0 }}>
						Phone Number
					</Typography>
					<MuiPhoneNumber
						defaultCountry={"us"}
						autoComplete="off"
						onChange={(phoneNumber: any) => setData({ ...data, phone: phoneNumber.toString() })}
						fullWidth
						variant="outlined"
						size="small"
						value={data.phone}
						required
						InputLabelProps={{ shrink: true }}
					/>

					<Typography variant="h6" fontSize={18} mt={{ xs: 12, md: 0 }}>
						Current Password
					</Typography>
					<CustomTextField
						onChange={handleOnChange}
						value={data.password}
						error={errors.password}
						name="password"
						type="password"
						placeholder="********"
						autoComplete="new-password"
					/>

					<Typography variant="h6" fontSize={18} mt={{ xs: 12, md: 0 }}>
						New Password
					</Typography>
					<CustomTextField
						onChange={handleOnChange}
						value={data.changePassword}
						error={errors.changePassword}
						name="changePassword"
						type="password"
						placeholder="********"
						autoComplete="new-password"
					/>

					<Box />
					<Box display="flex" alignItems="center" justifyContent="flex-end" gap={20}>
						<CustomButton variant="outlined" color="secondary" onClick={handleCancel}>
							Cancel
						</CustomButton>
						<CustomButton type="submit">Save Changes</CustomButton>
					</Box>
				</Box>
			</form>

			<OtpVerifyDialog open={otpDialog} onClose={closeOtpDialog} email={updatingEmail} />
		</PageLayout>
	);
};

export default AccountSettings;
