import { FormEvent, useEffect, useState } from "react";
import CustomButton from "../Common/CustomButton";
import { Box, Divider, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import PageLayout from "../PageLayout/PageLayout";
import { allRoutes } from "../../Routes/AllRoutes";
import CustomTextField, { Asterisk } from "../Common/CustomTextField";
import { getDefaultMsg, updateDefaultMsg } from "../../Services/chatWelcomeMsgConfigService";


const CustomerSupportDefaultWelcomeMsgConfig = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [siteDefaultMsgBeforeChatstart, setDefaultMsgBeforeChatstart] = useState<string>("");
    const [siteDefaultMsgAfterChatstart, setDefaultMsgAfterChatstart] = useState<string>("");

    useEffect(() => {
        getDetails();
    }, []);

    const getDetails = async () => {
        setLoading(true);
        try {
            const { data } = await getDefaultMsg();
            setDefaultMsgBeforeChatstart(data.msgBeforeChatStart)
            setDefaultMsgAfterChatstart(data.msgAfterChatStart)
        } catch (error: any) {
            toast.error(error);
        }
        setLoading(false);
    };

    const handleUpdate = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!siteDefaultMsgBeforeChatstart || !siteDefaultMsgAfterChatstart) return setError(true)
        else setError(false)
        setLoading(true);
        try {
            const body = {
                msgBeforeChatStart: siteDefaultMsgBeforeChatstart,
                msgAfterChatStart: siteDefaultMsgAfterChatstart
            };
            const { data: output } = await updateDefaultMsg(body);
            toast.success("Messages updated successfully!");
            navigate(allRoutes.CONFIGURATIONS);
        } catch (error: any) {
            toast.error(error);
        }
        setLoading(false);
    };

    const handleOnChangeMsgOne = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setDefaultMsgBeforeChatstart(value)
    };
    const handleOnChangeMsgSecond = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setDefaultMsgBeforeChatstart(value)
    };
    const handleCancel = () => {
        navigate(allRoutes.CONFIGURATIONS);
    };

    return (
        <PageLayout loading={loading}>
            <Typography variant="h5">Defualt Welcome Messages for the Support Chat</Typography>
            <Typography fontSize={15} mt={10}>
                These are all the messages that customer will see before and after starting the chat
            </Typography>
            <Divider sx={{ mt: 14, mb: 24 }} />

            <form onSubmit={handleUpdate}>
                <Box
                    display="grid"
                    gridTemplateColumns={{ xs: "1fr", md: "340px 1fr" }}
                    gap={{ xs: 10, md: 32 }}
                    alignItems="center"
                >
                    <Typography variant="h6" fontSize={18} mt={{ xs: 12, md: 0 }}>
                        Default Message Before Chat Starts <Asterisk />
                    </Typography>
                    <CustomTextField error={error && !siteDefaultMsgBeforeChatstart ? "Message field can not be empty" : ""} onChange={handleOnChangeMsgOne} name="title" value={siteDefaultMsgBeforeChatstart} placeholder="Enter Message" />
                    <Typography variant="h6" fontSize={18} mt={{ xs: 12, md: 0 }}>
                        Default Message After Chat Starts <Asterisk />
                    </Typography>
                    <CustomTextField error={error && !siteDefaultMsgAfterChatstart ? "Message field can not be empty" : ""} onChange={handleOnChangeMsgSecond} name="title" value={siteDefaultMsgAfterChatstart} placeholder="Enter Message" />
                    <Box />
                    <Box display="flex" alignItems="center" justifyContent="flex-end" gap={20}>
                        <CustomButton variant="outlined" color="secondary" onClick={handleCancel}>
                            Cancel
                        </CustomButton>
                        <CustomButton type="submit">Save Changes</CustomButton>
                    </Box>
                </Box>
            </form>
        </PageLayout>
    );
};

export default CustomerSupportDefaultWelcomeMsgConfig;
