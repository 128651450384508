import { Box, Typography } from "@mui/material";
import CustomDialog from "../Common/CustomDialog";
import { PersonRemoveOutlined } from "@mui/icons-material";
import CustomButton from "../Common/CustomButton";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useLoginStyles from "../Login/loginStyles";
import { useSelector } from "../../Redux/reduxHooks";
import { selectColors } from "../../Redux/Slices/generalSlice";

import CustomDropdown from "../Common/CustomDropdown";
import {
	assignRepresentativeToChat,
	getAllReps,
} from "../../Services/dashboardService";

interface ForgotPasswordDialogProps {
	open: boolean;
	onClose?: () => void;
	currentSelection?: { _id: string; name: string; companyId: string; mongoId: string } | null;
	onSuccess?: () => void;
	type?: "Office Manager" | "Manager";
	companyId?: string;
	channelId?: string;
	oldRepIds?: string[]; // Changed from oldRepId to oldRepIds
}

const ReassignChatModal = ({
	open,
	onClose,
	onSuccess,
	type,
	channelId,
	oldRepIds, // Changed from oldRepId to oldRepIds

}: ForgotPasswordDialogProps) => {
	const colors = useSelector(selectColors);
	const { IconSquareBox } = useLoginStyles();

	const [loading, setLoading] = useState<boolean>(false);
	const [selectedId, setSelectedId] = useState<string | undefined>("");
	const [allTechnicians, setAllTechnicians] = useState<Array<any>>([]);

	useEffect(() => {
		if (open) {
			getAllUsers();
		}
	}, [open]);

	const getAllUsers = async () => {
		setLoading(true);
		try {
			let { data }: any = await getAllReps(channelId || '');
			data = data.map((item: any) => ({ ...item, value: item._id, text: item.name }));
			setAllTechnicians(data);

			if (oldRepIds) {
				// Do nothing as we're now handling an array of IDs
			} else {
				setSelectedId("");
			}
		} catch (error: any) {
			toast.error(error);
		}
		setLoading(false);
	};

	const handleAssign = async () => {
		setLoading(true);
		try {
			const payload = {
				channelId,
				oldRepIds,
				newRepId: selectedId
			};
			await assignRepresentativeToChat(payload);

			await onSuccess?.();
			onClose?.();
			toast.success(`${type || "Representative"} assigned successfully!`);
		} catch (error: any) {
			toast.error(error);
		}
		setLoading(false);
	};

	const handleDropdown = (value: string) => {
		setSelectedId(value);
	};

	return (
		<CustomDialog open={open} onClose={onClose}>
			<IconSquareBox>
				<PersonRemoveOutlined />
			</IconSquareBox>

			<Typography variant="h2" my={16} textAlign="center">
				Assign {type || "Representative"}
			</Typography>
			<Typography fontSize={16} mb={16} textAlign="center" color={colors.textMid}>
				Please select a representative to reassign this customer to{" "}
			</Typography>

			<CustomDropdown
				label="Select option"
				options={allTechnicians}
				value={selectedId}
				onChange={handleDropdown}
				minWidth="100%"
			/>

			<Box display="grid" gridTemplateColumns="1fr 1fr" gap={10} mt={32}>
				<CustomButton variant="outlined" fullWidth disabled={loading} onClick={onClose}>
					Cancel
				</CustomButton>
				<CustomButton
					fullWidth
					disabled={!selectedId || loading}
					onClick={handleAssign}
				>
					Assign
				</CustomButton>
			</Box>
		</CustomDialog>
	);
};

export default ReassignChatModal;
