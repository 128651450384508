import { FormEvent, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import * as EmailValidator from "email-validator";
import PageLayout from "../PageLayout/PageLayout";
import { allRoutes } from "../../Routes/AllRoutes";
import CustomButton from "../Common/CustomButton";
import CustomTextField, { Asterisk } from "../Common/CustomTextField";
import { Box, Divider, Typography } from "@mui/material";
import axios from "axios";
import EmailSentDialog from "../Common/EmailSentModal";
import MuiPhoneNumber from "material-ui-phone-number";
import { getAuthHeader } from "../../Services/userService";

interface AccountSettingsData {
	name: string;
	email: string;
	phone: string;
}

const defaultData: AccountSettingsData = {
	name: "",
	email: "",
	phone: "",
};

const AddCustomerServiceRepresentative = () => {
	const navigate = useNavigate();
	const [data, setData] = useState<AccountSettingsData>(defaultData);
	const [errors, setErrors] = useState<AccountSettingsData>(defaultData);
	const [loading, setLoading] = useState<boolean>(false);
	const [open, setOpen] = useState<boolean>(false);

	const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setData((state) => ({ ...state, [name]: value }));
		setErrors((state) => ({ ...state, [name]: "" }));
	};

	const handlePhoneChange = (phoneNumber: string) => {
		setData((state) => ({ ...state, phone: phoneNumber }));
		setErrors((state) => ({ ...state, phone: "" }));
	};

	const validateData = () => {
		const updatedErrors = { ...errors };

		updatedErrors.name = data.name ? "" : "Name cannot be empty";
		updatedErrors.email = data.email
			? !EmailValidator.validate(data.email)
				? "Enter a valid email"
				: ""
			: "Email cannot be empty";
		updatedErrors.phone = data.phone ? "" : "Phone number cannot be empty";

		setErrors(updatedErrors);
		return !Object.values(updatedErrors).find(Boolean);
	};

	const handleUpdate = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (!validateData()) return;

		setLoading(true);
		try {
			const payload = {
				name: data.name,
				email: data.email,
				phone_no: data.phone,
			};

			await axios.post(`${process.env.REACT_APP_API_URL}/csr/add-new-csr`, payload, {
				headers: {
					...getAuthHeader(),
					'Content-Type': 'application/json'
				}
			});

			setOpen(true);
		} catch (error: any) {
			let specificError = error.response?.data?.message || error.message || "An error occurred";
			if (specificError.includes("Email already exists")) {
				specificError = "A user with this email already exists.";
				setErrors({ ...errors, email: specificError });
			} else {
				toast.error(specificError);
			}
		}
		setLoading(false);
	};

	const handleCancel = () => {
		navigate(-1);
	};

	return (
		<PageLayout loading={loading}>
			<Typography variant="h5">Register new Customer Service Representative</Typography>
			<Typography fontSize={15} mt={10}>
				After registration, an email will be sent to the customer service representative.
			</Typography>
			<Divider sx={{ mt: 14, mb: 24 }} />

			<form onSubmit={handleUpdate}>
				<Box
					display="grid"
					gridTemplateColumns={{ xs: "1fr", md: "340px 1fr" }}
					gap={{ xs: 10, md: 32 }}
					alignItems="center"
				>
					<Typography variant="h6" fontSize={18} mt={{ xs: 12, md: 0 }}>
						Name
						<Asterisk />
					</Typography>
					<CustomTextField
						onChange={handleOnChange}
						value={data.name}
						name="name"
						placeholder="Name"
						error={errors.name}
					/>

					<Typography variant="h6" fontSize={18} mt={{ xs: 12, md: 0 }}>
						Email address
						<Asterisk />
					</Typography>
					<CustomTextField
						onChange={handleOnChange}
						value={data.email}
						error={errors.email}
						name="email"
						type="email"
						placeholder="@example"
					/>

					<Typography variant="h6" fontSize={18} mt={{ xs: 12, md: 0 }}>
						Phone number
						<Asterisk />
					</Typography>
					<MuiPhoneNumber
						defaultCountry={"us"}
						autoComplete="off"
						onChange={(value) => handlePhoneChange(value as string)}
						error={!!errors.phone}
						helperText={errors.phone}
						fullWidth
						variant="outlined"
						size="small"
						value={data.phone}
						required
						InputLabelProps={{ shrink: true }}
					/>

					<Box />
					<Box display="flex" alignItems="center" justifyContent="flex-end" gap={20}>
						<CustomButton variant="outlined" color="secondary" onClick={handleCancel}>
							Cancel
						</CustomButton>
						<CustomButton type="submit">Register Customer Service Representative</CustomButton>
					</Box>
					<EmailSentDialog open={open} onClose={() => navigate(allRoutes.CSR)} />
				</Box>
			</form>
		</PageLayout>
	);
};

export default AddCustomerServiceRepresentative;
